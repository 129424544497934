import { RecursivePartial } from '../base';
import { BaseLayoutElement } from './_base-element';
import { LayoutElementType } from './layout-element-type';
import { IconElement } from './icon';
import { TaskFieldLayoutElement } from './task-field';
import { LayoutElementUnknown } from './_unknown';

export type LayoutElement = BoxLayoutElement | TaskFieldLayoutElement | IconElement;

export class BoxLayoutElement extends BaseLayoutElement {
    type: LayoutElementType.Box  = LayoutElementType.Box;

    direction: 'hor' | 'vert' = 'hor';
    wrap?: boolean;
    gap?: number | [number, number];
    center?: boolean;
    centerCenter?: boolean;
    endJustified?: boolean;
    start?: boolean;
    end?: boolean;

    items?: LayoutElement[];

    constructor(json?: RecursivePartial<BoxLayoutElement>) {
        super(json);
        this.parseBoxLayout(json || {});
    }

    parse(json: RecursivePartial<BoxLayoutElement>): BoxLayoutElement {
        super.parse(json);
        this.parseBoxLayout(json);
        return this;
    }

    private parseBoxLayout(json: RecursivePartial<BoxLayoutElement>): void {
        BoxLayoutElement.assignFields<BoxLayoutElement>(this, json, [
            'direction',
            'wrap',
            'gap',
            'center',
            'centerCenter',
            'endJustified',
            'start',
            'end'
        ]);
        if (json.items && Array.isArray(json.items)) {
            this.items = [];
            for (const le of json.items) {
                this.items.push(createLayoutElement(le!));
            }
        }
        this.setCssClasses();
        this.setCssStyles();
    }

    getCssStyles(): void {
        super.setCssStyles();
        if (this.gap) {
            this._styles!.gap =  Array.isArray(this.gap) ? `${this.gap[0]}px ${this.gap[1]}px` : `${this.gap}px`;
        }
    }

    setCssClasses(): void {
        super.setCssClasses();
        this._classes!.layout = true;
        if (this.direction == 'vert') {
            this._classes!.vertical = true;
        }
        if (this.wrap) {
            this._classes!.wrap = true;
        }
        if (this.center) {
            this._classes!.center = true;
        }
        if (this.start) {
            this._classes!.start = true;
        }
        if (this.end) {
            this._classes!.end = true;
        }
        if (this.centerCenter) {
            this._classes!['center-center'] = true;
        }
        if (this.endJustified) {
            this._classes!['end-justified'] = true;
        }
    }

}

export type LayoutElementClassType = {
    [LayoutElementType.Box]: BoxLayoutElement,
    [LayoutElementType.Icon]: IconElement,
    [LayoutElementType.TaskField]: TaskFieldLayoutElement,
}

export const LayoutElementClass = {
    [LayoutElementType.Box]: BoxLayoutElement,
    [LayoutElementType.Icon]: IconElement,
    [LayoutElementType.TaskField]: TaskFieldLayoutElement,
}

export function createLayoutElement<T extends LayoutElementType>(le: RecursivePartial<LayoutElement>): LayoutElementClassType[T] {
    if (le?.type && LayoutElementClass[le.type]) {
        return new LayoutElementClass[le.type](le as any) as LayoutElementClassType[T];
    }
    else {
        console.log('[createLayoutElement] unknown element type:', le);
        return new LayoutElementUnknown(le as any) as any;
    }
}
