import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

import { ErrorMessageModule } from '@shared/_utils/error-message.module';
import { AutoFocusModule } from '@shared/_utils/autofocus.module';
import { SelectIconDrawerModule } from '@shared/_drawers/select-icon';
import { SelectColorDrawerModule } from '@shared/_drawers/select-color';
import { ProjectSelectComponent } from '@shared/_controls/project-select.component';
import { TagModule } from '@shared/_views/tag';

import { BaseDrawerComponent } from '@base/base-drawer.component';
import { G, TAG_COLORS, Tag, VzTag, sortStringsBy } from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { ApiService } from '@services/api.service';
import { StoreService } from '@services/store.service';
import { removeH } from '@base/base-animations';

@Component({
    selector: 'vz-tag-drawer',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.less'],
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        NzDrawerModule, NzInputModule, NzSelectModule, NzButtonModule, NzRadioModule, NzPopconfirmModule,
        NzCheckboxModule,
        ErrorMessageModule, AutoFocusModule, TagModule, SelectIconDrawerModule, SelectColorDrawerModule,
        ProjectSelectComponent
    ],
    animations: [removeH]
})
@Tag('TagDrawerComponent')
export class TagDrawerComponent extends BaseDrawerComponent implements OnChanges {

    @Input() tagId?: string;
    @Input() projectId?: string;

    text: string = 'Новый тег';
    description?: string;
    icon: string = 'vzi-tag-lines';
    color: string = '#0060AA';
    type: number = 1;
    vis: number = 1;
    uniqueGroup?: boolean;
    pid?: string;
    showSelectIcon?: boolean;
    showSelectColor?: boolean;
    tags: VzTag[] = [];
    pTag?: VzTag;
    previewTag?: VzTag;
    isCorrect: boolean = false;
    allTags: VzTag[] = [];
    allowedProjectIds?: string[];

    readonly TTAG = 1;
    readonly TCAT = 2;
    readonly VORG = 1;
    readonly VPRJ = 2;

    compareById = (a: any, b: any) => a?.id == b?.id;

    constructor(
        protected _store: StoreService,
        private _api: ApiService,
    ) {
        super(_store);
        this._S.tags = this._store.state('tags').subscribe(st => {
            this.allTags = Object.values(st.items).filter(tag => tag.orgId == this.activeOrgId && !tag.parentId).sort(sortStringsBy('text'));
            this.updateAllowedTags();
        });
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.updateAllowedProjects();
        this.updateAllowedTags();
        this._L('ngOnChanges', _changes, 'projectId:', this.projectId, 'tagId:', this.tagId);
        if (!this.tagId || this.tagId == 'new') {
            this.tagId = undefined;
            this.text = 'Новый тег ' + Math.trunc(Math.random() * 100);
            this.description = undefined;
            this.icon = 'vzi-tag-lines';
            this.color = TAG_COLORS[Math.trunc(Math.random() * TAG_COLORS.length)];
            this.type = this.TCAT;
            this.uniqueGroup = false;
            this.pTag = undefined;
            this.pid = undefined;
            if (this.projectId) {
                this.pid = this.projectId;
                // const ptags = this.tags.filter(t => t.projectId == this.pid);
                // if (ptags.length) {
                //     this.pTag = ptags[0];
                // }
                this.vis = this.VPRJ;
            }
            this._L('ngOnChanges', 'projectId:', this.projectId, 'pid:', this.pid);
            this.updatePreview();
        }
        else {
            const tag = this._store.getState('tags').items[this.tagId];
            if (tag) {
                this.text = tag.text;
                this.description = tag.description;
                this.icon = tag.icon;
                this.color = tag.color!;
                this.type = tag.parentId ? this.TTAG : this.TCAT;
                this.uniqueGroup = tag.uniqueGroup;
                this.pTag = tag.parentId ? this._store.getState('tags').items[tag.parentId] : undefined;
                this.pid = this.pTag?.projectId;
                this.vis = this.pid ? this.VPRJ : this.VORG;
                this.updatePreview();
            }
            else {
                this.lError = new HttpApiError('Тег не найден');
            }
        }
        this._L('ngOnChanges', 'pid:', this.pid);
    }

    updateAllowedProjects(): void {
        if (this.projectId) {
            this.allowedProjectIds = [this.projectId];
        }
        else {
            if (this.granted['org.admin']) {
                this.allowedProjectIds = undefined;
            }
            else {
                this.allowedProjectIds = Object.keys(this.pGranted).filter(id => id != 'global' && this.pGranted[id].__manager);
            }
        }
    }

    updateAllowedTags(): void {
        this.tags = this.allTags.filter(tag =>
            (this.vis == this.VPRJ && this.pid && (this.granted['org.admin'] || this.pGranted[this.pid]?.__manager) && this.pid == tag.projectId)
            || (this.vis == this.VORG && this.granted['org.admin'] && !tag.projectId)
        );
    }

    submit(): void {
        this.updatePreview();
        if (this.isCorrect && this.previewTag) {
            if (this.tagId) {
                this._api.updateTag(this.tagId, this.previewTag).subscribe({
                    next: () => this.close(),
                    error: err => this.__error(err)
                });
            }
            else {
                this._api.createTag(this.previewTag).subscribe({
                    next: tag => {
                        if (tag?.id) {
                            this.close(tag);
                        }
                    },
                    error: err => this.__error(err)
                });
            }
        }
    }

    setIcon(icon?: string): void {
        if (icon) {
            this.icon = icon;
        }
        this.updatePreview();
        this.showSelectIcon = false;
    }

    setColor(color?: string): void {
        if (color) {
            this.color = color;
        }
        this.updatePreview();
        this.showSelectColor = false;
    }

    delete(): void {
        if (this.tagId) {
            this._api.deleteTag(this.tagId).subscribe({
                next: () => this.close(),
                error: err => this.__error(err)
            });
        }
    }

    @G('typeChanged', true)
    typeChanged(): void {
        this._L('typeChanged', 'type:', this.type, 'vis:', this.vis);
        this.updateAllowedTags();
        if (this.type == this.TCAT) {
            this.pTag = undefined;
        }
        else if (this.type == this.TTAG) {
            this._L('typeChanged', 'type == TTAG, tagId:', this.tagId);
            this.updateParentTag();
            this._L('typeChanged', 'pTag:', this.pTag, '\n\ttags:', this.tags);
        }
        this.updatePreview();
    }

    @G('visChanged', true)
    visChanged(): void {
        this._L('visChanged', 'vis:', this.vis, 'type:', this.type);
        this.updateAllowedTags();
        if (this.vis == this.VORG) {
            if (this.type == this.TTAG) {
                this.updateParentTag();
            }
        }
        else if (this.vis == this.VPRJ) {
            const pids = this.allowedProjectIds || Object.keys(this.pGranted).filter(pid => pid != 'global' && this.pGranted[pid].__manager) || [];
            if (pids.length) {
                this.pid = pids[0];
                if (!this.pTag || this.pTag.projectId != this.pid) {
                    this.updateParentTag();
                }
            }
            else {
                this.vis = this.VORG;
            }
            this._L('visChanged', 'vis == VPRJ, pid:', this.pid, 'pids:', pids);
        }
        this.updatePreview();
    }

    projectChanged(): void {
        this.updateAllowedTags();
        if (this.type == this.TTAG && (!this.pTag || this.pTag.projectId != this.pid)) {
            this.updateParentTag();
        }
    }

    @G('updateParentTag', true)
    updateParentTag(): void {
        this._L('updateParentTag', 'tagId:', this.tagId);
        if (this.tagId) {
            const tag = this._store.getState('tags').items[this.tagId];
            if (tag?.parentId) {
                this.pTag = this._store.getState('tags').items[tag.parentId];
            }
        }
        else {
            this.pTag = this.tags.find(t => (this.vis == this.VORG && !t.projectId) || (this.vis == this.VPRJ && t.projectId == this.pid));
        }
        this._L('updateParentTag', 'pTag:', this.pTag);
    }

    @G('updatePreview', true)
    updatePreview(): void {
        this._L('updatePreview', 'pid:', this.pid, 'type:', this.type, 'pTag:', this.pTag);
        this.previewTag = new VzTag({
            text: this.text?.trim() || '?',
            description: this.description,
            color: this.color,
            icon: this.type == this.TCAT ? this.icon : undefined,
            parentId: this.type == this.TTAG ? this.pTag?.id : undefined,
            orgId: this.activeOrgId,
            projectId: this.vis == this.VPRJ ? this.pid : undefined,
            uniqueGroup: this.uniqueGroup
        });
        this._L('updatePreview', 'previewTag:', this.previewTag);
        this.isCorrect = !!this.text
            && !!this.text.trim()
            && !!this.color
            && (
                (this.type == this.TTAG && !!this.previewTag?.parentId)
                || (this.type == this.TCAT && !this.previewTag?.parentId && !!this.icon)
            );
    }

}
