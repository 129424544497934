import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EmbeddedViewRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';

import { NzDrawerModule, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzContextMenuService, NzDropDownModule, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';

import { IVzMenuItem } from '@models';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'vz-context-menu',
    template: `
        <nz-dropdown-menu #ddMenu="nzDropdownMenu">
            @if (menuItems?.length) {
                <ul nz-menu>
                    @if (isTpl(menuTitle)) {
                        <span class="vz-menu-title">
                            <ng-container [ngTemplateOutlet]="$any(menuTitle)" [ngTemplateOutletContext]="{ $implicit: data }" />
                        </span>
                    }
                    @else if (menuTitle) {
                        <span class="vz-menu-title">{{ menuTitle }}</span>
                    }
                    @for (mi of menuItems; track $index) {
                        @if (mi.id == '-') {
                            <li nz-menu-divider></li>
                        }
                        @else {
                            <li nz-menu-item [nzDanger]="mi.danger" [nzDisabled]="mi.disabled" (click)="menuItemClick(mi)">
                                <i [ngClass]="mi.icon"></i> {{ mi.title }}
                            </li>
                        }
                    }
                </ul>
            }
        </nz-dropdown-menu>

        <ng-template #drawerTpl>
            @if (menuItems?.length) {
                <div class="layout vertical fit scroll-y gap05">
                    <span class="bg-dd pv05 ph1 fs-xl txt-l tac">
                        @if (isTpl(menuTitle)) {
                            <ng-container [ngTemplateOutlet]="$any(menuTitle)" [ngTemplateOutletContext]="{ $implicit: data }" />
                        }
                        @else if (menuTitle) {
                            {{ menuTitle }}
                        }
                    </span>
                    @for (mi of menuItems; track $index) {
                        @if (mi.id == '-') {
                            <hr />
                        }
                        @else {
                            <button style="justify-content: start"
                                nz-button
                                nzType="text"
                                nzSize="large"
                                [nzDanger]="mi.danger"
                                [disabled]="mi.disabled"
                                (click)="menuItemClick(mi)">
                                <i class="fs-xxl mr1" [ngClass]="mi.icon"></i> {{ mi.title }}
                            </button>
                        }
                    }
                </div>
            }
        </ng-template>
    `,
    standalone: true,
    imports: [
        CommonModule,
        NzDropDownModule, NzDrawerModule, NzButtonModule,
    ],
})
export class ContextMenuComponent implements AfterViewInit, OnDestroy {
    @Input() menuItems?: IVzMenuItem[];
    @Input() menuTitle?: string | TemplateRef<any>;
    @Input() mobile?: boolean;
    @Input() event?: MouseEvent | { x: number, y: number };
    @Input() data?: any;
    @Output() onItemClick: EventEmitter<{ mi: IVzMenuItem, data: any }> = new EventEmitter();
    @Output() onClose: EventEmitter<void> = new EventEmitter();

    @ViewChild('ddMenu', { static: false }) ddMenu?: NzDropdownMenuComponent;
    @ViewChild('drawerTpl', { static: false }) drawerTpl?: TemplateRef<any>;

    drawerRef?: NzDrawerRef;
    viewRef?: EmbeddedViewRef<any>;
    routerSub?: Subscription;

    constructor(
        private _router: Router,
        private _menu: NzContextMenuService,
        private _drawer: NzDrawerService
    ) {
        this.routerSub = this._router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                if (this.drawerRef) {
                    this.drawerRef.close();
                    this.drawerRef = undefined;
                    this._router.navigateByUrl(this._router.url, {  replaceUrl: true });
                }
            }
        });
    }

    ngAfterViewInit(): void {
        if (!this.menuItems) {
            return;
        }
        if (this.mobile) {
            this.drawerRef = this._drawer.create({
                nzClosable: false,
                nzCloseOnNavigation: true,
                nzContent: this.drawerTpl!,
                nzMaskClosable: true,
                nzMask: true,
                nzPlacement: 'bottom',
                nzNoAnimation: false,
                nzWrapClassName: 'context-menu',
                nzHeight: Math.min(this.menuItems.filter(mi => mi.id != '-').length * 48 + this.menuItems.filter(mi => mi.id == '-').length * 13 + 58, 300) + 'px',
            });
            this.drawerRef.afterClose.subscribe(() => this.onClose.emit());
        }
        else if (this.event) {
            this.viewRef = this._menu.create(this.event, this.ddMenu!);
            this.viewRef.onDestroy(() => this.onClose.emit());
        }
    }

    ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
        }
    }

    menuItemClick(mi: IVzMenuItem): void {
        if (this.drawerRef) {
            this.drawerRef.close();
            this.drawerRef = undefined;
        }
        this.onItemClick.emit({ mi, data: this.data });
    }

    isTpl(v: any): boolean {
        return v && v instanceof TemplateRef;
    }

}
