import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { DmTableGrouppedRows, DmTableModule } from '@dimanoid/ngx-dm-table';
import { DmTableController, DmTableControllerState } from '@dimanoid/ngx-dm-table';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { LoadingComponent } from '@shared/_utils/loading.component';
import { ErrorMessageModule } from '@shared/_utils/error-message.module';
import { AvatarModule } from '@shared/_views/avatar';
import { CtrlSearchModule } from '@shared/_controls/ctrl-search';
import { ChatRowComponent } from '@shared/_table-rows/chat';

import { BaseComponent } from '@base/base.component';
import { Chat, ChatFilter, ChatType, StateCacheMapsType, Tag, sortStringsBy } from '@models';
import { InputBoolean, InputNumber } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';
import { FilterController } from '@shared/_controls/filter-form';

@Component({
    selector: 'vz-chats-select, [vz-chats-select]',
    templateUrl: './chats-select.component.html',
    styleUrls: ['./chats-select.component.less'],
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NzButtonModule, NzCheckboxModule,
        DmTableModule,
        LoadingComponent, ErrorMessageModule, AvatarModule, CtrlSearchModule, ChatRowComponent,
    ]
})
@Tag('ChatsSelectComponent')
export class ChatsSelectComponent extends BaseComponent implements OnInit {

    @Input() items?: Chat[];
    @Input() selected: string[] = [];
    @Input() okText: string = 'Применить';
    @Input() @InputBoolean() single?: boolean | string = false;
    @Input() @InputBoolean() noFilters?: boolean | string = false;
    @Input() @InputBoolean() returnObjects?: boolean | string = false;
    @Input() @InputNumber() avatarSize: number | string = 46;
    @Output() onClose: EventEmitter<Chat[] | string[]> = new EventEmitter();

    loading = false;
    controller: DmTableController<Chat, string> = new DmTableController((t: Chat) => t.id!, true);
    filter = new ChatFilter(() => this.maps.orgs, () => this.userId);
    filterController = new FilterController(this.filter, { route: this._route, router: this._router, qsKey: 'chatsFilter' });
    maps: StateCacheMapsType = {};
    cState!: DmTableControllerState;

    constructor(
        protected _store: StoreService,
        @Optional() private _drawerRef: NzDrawerRef<string[], Chat[] | string[] | undefined>,
        private _router: Router,
        private _route: ActivatedRoute,
    ) {
        super(_store);
        this._store.fillWithStates(['orgs', 'persons'], this.maps);
        this.controller.filterFn = (item, filter) => this.filter.match(item, this.filter.parse(filter));
        this.controller.state.subscribe(st => this.cState = st);
        this.controller.sortFn = items => {
            const chs = items.sort(sortStringsBy('title'));
            const mainChat = chs.find(ch => ch.isDefault);
            const favChat = chs.find(ch => ch.thread.commentators.length == 1 && ch.thread.commentators[0]?.id == this.userId && ch.type == ChatType.Direct);
            items = chs.filter(ch => (!mainChat || ch.id != mainChat.id) && (!favChat || ch.id != favChat.id));
            if (mainChat) {
                items.unshift(mainChat);
            }
            if (favChat) {
                items.unshift(favChat);
            }
            return items;
        };
    }

    ngOnInit(): void {
        this._L('ngOnInit', this.items);
        if (!this.items) {
            this.items = Object.values(this._store.getState('chats').items).filter(chat => chat.orgId == this.activeOrgId);
        }
        this.setItems(this.items);
    }

    setItems(items: Chat[]): void {
        items.filter(ch => ch.type == ChatType.Direct && !ch.pluginId).forEach(ch => {
            let list = '';
            if(ch._activeMembers && ch._activeMembers.length == 1 && ch._activeMembers[0] == this.userId) {
                list = 'Избранное';
            }
            else if (ch._activeMembers?.length) {
                list = ch._activeMembers?.filter(id => id != this.userId).map(id => this.maps.persons![id].name || '#' + id).join(', ');
            }
            ch.title = list;
        });
        const gItems: DmTableGrouppedRows<Chat>[] = [
            {
                rows: items.filter(ch => ch.type != ChatType.Direct && !ch.pluginId),
                data: { title: 'Групповые чаты', type: 1 }
            },
            {
                rows: items.filter(ch => ch.type == ChatType.Direct && !ch.pluginId),
                data: { title: 'Персональные чаты', type: 2 }
            },
            {
                rows: items.filter(ch => !!ch.pluginId),
                data: { title: 'Чаты с клиентами', type: 3 }
            }
        ];
        this.controller.setItems(gItems);
        if (this.selected && this.selected.length > 0) {
            this.controller.setSelected(this.selected, true);
        }
    }

    close(selected?: Chat[] | string[]): void {
        if (this._drawerRef) {
            this._drawerRef.close(selected);
        }
        else {
            this.onClose.emit(selected);
        }
    }

}
