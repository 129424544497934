import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ksize'
})
export class KsizePipe implements PipeTransform {

    transform(value: any, frac = 0, space = ''): any {
        if (value === undefined || value === null) {
            value = 0;
        }
        value = +value;
        if (value > 300 * 1024 * 1024 * 1024 && (value / 1024 / 1024 / 1024 / 1024).toFixed(frac) != '0') {
            return (value / 1024 / 1024 / 1024 / 1024).toFixed(frac) + space + 'Тб';
        }
        else if (value > 300 * 1024 * 1024 && (value / 1024 / 1024 / 1024).toFixed(frac) != '0') {
            return (value / 1024 / 1024 / 1024).toFixed(frac) + space + 'Гб';
        }
        else if (value > 300 * 1024 && (value / 1024 / 1024).toFixed(frac) != '0') {
            return (value / 1024 / 1024).toFixed(frac) + space + 'Мб';
        }
        else if (value > 1024 && (value / 1024).toFixed(frac) != '0') {
            return (value / 1024).toFixed(frac) + space + 'Кб';
        }
        else {
            return Math.trunc(value) + space + 'б';
        }
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [KsizePipe],
    exports: [KsizePipe]
})
export class KsizePipeModule { }

