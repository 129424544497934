import { Component, Input } from '@angular/core';

import { BaseDrawerComponent } from '@base/base-drawer.component';
import { Tag, TAG_COLORS } from '@models';
import { InputNumber } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';

@Component({
    selector: 'vz-select-color',
    templateUrl: './select-color-drawer.component.html',
    styleUrls: ['./select-color-drawer.component.less']
})
@Tag('SelectColorDrawerComponent')
export class SelectColorDrawerComponent extends BaseDrawerComponent {
    colors = TAG_COLORS;
    @Input() @InputNumber() op: number = 1;

    constructor(
        protected _store: StoreService,
    ) {
        super(_store);
    }

}
