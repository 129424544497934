import { Directive, EventEmitter, ElementRef, Output, HostListener } from '@angular/core';

@Directive({
    selector: '[fileSelect]',
})
export class FileSelectDirective {
    @Output() fileSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(private element: ElementRef) {
    }

    public getFilters() {
    }

    public isEmptyAfterSelection(): boolean {
        return !!this.element.nativeElement.attributes.multiple;
    }

    @HostListener('change')
    onChange() {
        this.fileSelected.emit(this.element.nativeElement.files);
    }
}
