import { Directive, EventEmitter, Input, Output, HostBinding, HostListener, OnDestroy } from '@angular/core';

import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

import { InputBoolean } from '@models/utils/coercions';

@Directive({
    selector: '[fileDrop]',
})
export class FileDropDirective implements OnDestroy {
    @HostBinding('class.fileDrop-file-over') isFileOver = false;

    @Input() @InputBoolean() fileDropEnabled: boolean | string = true;
    @Output() fileOver: EventEmitter<boolean> = new EventEmitter();
    @Output() fileDrop: EventEmitter<File[]> = new EventEmitter();

    isFileOverUpdate: Subject<boolean> = new Subject();
    isFileOverSubscription?: Subscription;

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        if (!this.fileDropEnabled) {
            return;
        }
        const transfer = this._getTransfer(event);
        if (!transfer) {
            return;
        }
        if (transfer.files && transfer.files.length > 0) {
            event.preventDefault();
            this.fileDrop.emit(transfer.files as any);
        }
        this.isFileOverUpdate.next(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent) {
        if (!this.fileDropEnabled) {
            return;
        }
        const transfer = this._getTransfer(event);
        if (!transfer || !this._haveFiles(transfer.types)) {
            return;
        }

        transfer.dropEffect = 'copy';
        event.preventDefault();

        this.isFileOverUpdate.next(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent): any {
        event.preventDefault();
        this.isFileOverUpdate.next(false);
    }

    constructor() {
        this.isFileOverSubscription = this.isFileOverUpdate.pipe(distinctUntilChanged(), debounceTime(200)).subscribe((v) => {
            this.isFileOver = v;
            this.fileOver.next(v);
        });
    }

    ngOnDestroy(): void {
        this.isFileOverSubscription?.unsubscribe();
        this.isFileOverSubscription = undefined;
    }

    private _getTransfer(event: DragEvent): DataTransfer | undefined {
        return event.dataTransfer ? event.dataTransfer : (event as any).originalEvent?.dataTransfer;
    }

    private _haveFiles(types: any): any {
        if (!types) {
            return false;
        }
        if (types.indexOf) {
            return types.indexOf('Files') !== -1;
        }
        else if (types.contains) {
            return types.contains('Files');
        }
        return false;
    }

}
