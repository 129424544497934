import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';

import { AvatarModule } from '@shared/_views/avatar';

import { Chat, ChatStatus, ChatType, strings2Color } from '@models';
import { InputBoolean, InputNumber } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';

@Component({
    selector: 'vz-chat-avatar, [vz-chat-avatar]',
    template: `
        @if (chat) {
            @if (chat.status == CS.Archived || chat.status == CS.Finished) {
                <vz-avatar [item]="$any({ initials: 'vzi-cabinet', color: 'var(--vz-textColorLL)', textColor: 'var(--vz-textColor)' })" withName="false" noTooltip="true" noBorder="true" [size]="size" />
            }
            @if (chat.status != CS.Archived && chat.status != CS.Finished) {
                @switch (chat.type) {
                    @case (CT.Private) {
                        <vz-avatar [item]="$any({ initials: 'vzi-lock', color: 'var(--vz-textColorL)' })" withName="false" noTooltip="true" noBorder="true" [size]="size" />
                    }
                    @case (CT.Public) {
                        <vz-avatar [item]="$any({ initials: '#', color: 'var(--vz-textColorL)' })" withName="false" noTooltip="true" noBorder="true" [size]="size" />
                    }
                    @case (CT.Direct) {
                        @if (selfOnly) {
                            <vz-avatar [item]="$any({ initials: 'vzi-bookmark2', color: 'var(--vz-primaryColorL)' })" withName="false" noTooltip="true" noBorder="true" [size]="size" />
                        }
                        @else {
                            <vz-avatar [item]="avatar" withName="false" noTooltip="true" noBorder="true" [size]="size" [type]="group ? 'group' : 'user'" />
                        }
                    }
                }
            }
            @if (withName) {
                <span class="ml025 elli">{{ selfOnly ? 'Избранное' : title }}</span>
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, AvatarModule]
})
export class ChatAvatarComponent implements OnChanges {
    @HostBinding('class.layout') _hostLayout: boolean = true;
    @HostBinding('class.center') _hostCenter: boolean = true;

    @Input() chat?: Chat;
    @Input() @InputBoolean() withName: boolean | string = false;
    @Input() @InputNumber() size: number | string = 18;

    title: string = '';
    selfOnly: boolean = false;
    group: boolean = false;
    avatar: any;

    CT = ChatType;
    CS = ChatStatus;

    constructor(private _store: StoreService) { }

    ngOnChanges(): void {
        this.title = '';
        this.selfOnly = false;
        this.group = false;
        this._hostLayout = !!this.withName;
        this._hostCenter = !!this.withName;
        if (!this.chat) {
            return;
        }
        const userId = this._store.getState('user').userId;
        if (this.chat.type != ChatType.Direct) {
            this.title = this.chat.title;
        }
        else if (this.chat.thread.commentators.length == 1 && this.chat.thread.commentators[0]?.id == userId) {
            this.selfOnly = true;
        }
        else if (this.chat.thread.commentators.length) {
            const um = this._store.getState('persons').items;
            const ids = this.chat.thread.commentators.filter(cm => cm.id != userId).map(cm => cm.id!);
            this.title = ids.map(id => um[id].name || '#' + id).join(', ');
            if (ids.length == 1) {
                this.avatar = um[ids[0]];
            }
            else if (ids.length == 2) {
                this.avatar = { initials: um[ids[0]].name.substring(0, 1) + um[ids[1]].name.substring(0, 1), color: strings2Color(ids) };
                this.group = true;
            }
            else {
                this.avatar = { initials: ids.length > 99 ? '**' : '' + ids.length, color: strings2Color(ids) };
                this.group = true;
            }
        }
        else {
            this.title = '';
        }
    }

}
