import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { DmTableControllerState, DmTableModule } from '@dimanoid/ngx-dm-table';

import { LoadingComponent } from '@shared/_utils/loading.component';
import { ErrorMessageModule } from '@shared/_utils/error-message.module';
import { AvatarModule } from '@shared/_views/avatar';
import { CtrlSearchModule } from '@shared/_controls/ctrl-search';
import { TaskCardComponent } from '@shared/_cards/task';
import { TableSortButtonComponent } from '@shared/_controls/table-sort-button.component';
import { AutoFocusModule } from '@shared/_utils/autofocus.module';

import { DmTableController } from '@dimanoid/ngx-dm-table';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { BaseComponent } from '@base/base.component';
import { Task, Tag, TaskLinkType } from '@models';
import { ApiService } from '@services/api.service';
import { StoreService } from '@services/store.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { InputBoolean } from '@models/utils/coercions';

@Component({
    templateUrl: './tasks-select.component.html',
    styleUrls: ['./tasks-select.component.less'],
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NzButtonModule, NzInputModule, NzCheckboxModule,
        DmTableModule,
        LoadingComponent, ErrorMessageModule, AvatarModule, CtrlSearchModule, TaskCardComponent, TableSortButtonComponent,
        AutoFocusModule
    ]
})
@Tag('TasksSelectComponent')
export class TasksSelectComponent extends BaseComponent implements OnInit {
    @Input() projectId?: string;
    @Input() excludeTaskId?: string;
    @Input() linkType?: TaskLinkType;
    @Input() tasks?: Task[];
    @Input() selected: string[] = [];
    @Input() @InputBoolean() multiple?: boolean | string = false;

    controller: DmTableController<Task, string> = new DmTableController((t: Task) => t.id!);
    inputDebouncer: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    searchText?: string | null;
    f: string | null = null;
    cState!: DmTableControllerState;

    constructor(
        protected _store: StoreService,
        private _api: ApiService,
        private _cdr: ChangeDetectorRef,
        private drawerRef: NzDrawerRef<string[], Task[] | undefined>,
    ) {
        super(_store);
        this._S.debouncer = this.inputDebouncer.pipe(skip(1), debounceTime(this.tasks?.length ? 50 : 300), distinctUntilChanged()).subscribe(f => this.search(f));
        this.controller.state.subscribe(st => this.cState = st);
        this.loading = false;
    }

    ngOnInit(): void {
        if (this.projectId) {
            const abbr = this._store.getState('projects').items[this.projectId]?.abbr;
            if (abbr && !this.f) {
                this.f = abbr + '-';
                this.search(this.f);
            }
        }
        else {
            this.search('');
        }
    }

    inputChange(text: string): void {
        this.inputDebouncer.next(text);
    }

    search(text: string | null): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.searchText = text?.trim();
        if (this.searchText == null) {
            this.loading = false;
            return;
        }
        if (this.tasks?.length) {
            this.controller.setItems(this.tasks.filter(t =>
                (
                    !this.searchText
                    || t.shortId.toLocaleLowerCase().startsWith(this.searchText.toLocaleLowerCase())
                    || t.subj.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
                )
                && (!this.excludeTaskId || t.id != this.excludeTaskId)
            ));
            this.loading = false;
            // this.checkSearch();
        }
        else {
            this.__finalize(this._api.searchTasks(this.activeOrgId!, this.searchText, { projectId: this.projectId }), () => this.loading = false, { cdr: this._cdr }).subscribe({
                next: items => this.controller.setItems(items.filter(t => !this.excludeTaskId || t.id != this.excludeTaskId)),
                error: err => this.__error(err)
            });
        }
    }

    checkSearch(): void {
        this.loading = false;
        const st = this.inputDebouncer.getValue();
        if (st != this.searchText) {
            this.search(st);
        }
    }

    close(selected?: Task[]): void {
        this.drawerRef.close(selected);
    }

}
