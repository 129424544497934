import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';

import { finalize } from 'rxjs';

import { ApiService } from '@services/api.service';
import { Tag, Task, stopEvent } from '@models';

@Component({
    selector: 'vz-task-favorite, [vz-task-favorite]',
    template: `
        @if (task) {
            <div class="layout center-center">
                @if (working) {
                    <div class="loader micro"></div>
                }
                @else {
                    <i class="fs-3xl top-2"
                        [ngClass]="task.flags.favorite ? 'vzi-star txt-warn' : 'vzi-star-o txt-l'"
                        (click)="toggle($event)">
                    </i>
                }
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Tag('TaskFavoriteComponent')
export class TaskFavoriteComponent implements OnChanges {
    @Input() task?: Task;
    working: boolean = false;

    constructor(private _cdr: ChangeDetectorRef, private _api: ApiService) { }

    ngOnChanges(_changes: SimpleChanges): void {
        this._cdr.markForCheck();
    }

    toggle(e: MouseEvent): void {
        stopEvent(e);
        if (this.working || !this.task?.id) {
            return;
        }
        this.working = true;
        this._api.setTaskConditions(this.task.id, { favorite: !this.task.flags.favorite }).pipe(finalize(() => {
            this.working = false;
            this._cdr.markForCheck();
        })).subscribe(() => this._cdr.markForCheck());
        this._cdr.markForCheck();
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [TaskFavoriteComponent],
    exports: [TaskFavoriteComponent]
})
export class TaskFavoriteModule { }
